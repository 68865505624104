import api from '../api/api';

const searchCanon = async (query) => {
  try {
    const response = await api.get(`/canon_search?query=${encodeURIComponent(query)}`);
    console.log('Canon search results:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error searching canon:', error);
    throw error;
  }
};

export default searchCanon;