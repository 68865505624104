import api from '../api/api';

const publishCanon = async (postId) => {
  try {
    const response = await api.post(`/canon_publish/${postId}`);
    return response.data;
  } catch (error) {
    console.error('Error publishing canon:', error);
    throw error;
  }
};

export default publishCanon;