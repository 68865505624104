// SearchResultItem.js - This component displays a single search result item and associated buttons.

import React from 'react';
import { Box, Button, HStack, VStack, useToast } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import formatDate from '../utils/formatDate';
import { stripHtmlAndDecode } from '../utils/textUtils';
import publishCanon from '../utils/publishCanon';

const publishPost = async (postId, toast) => {
  try {
    // Call the publishCanon function and wait for it to complete
    await publishCanon(postId);

    // Show a success toast message
    toast({
      title: "Post Published",
      description: "The post has been successfully published.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });

    console.log(`Post with ID ${postId} published successfully.`);
  } catch (error) {
    // Handle errors and show an error toast message
    console.error("Error publishing post:", error);

    toast({
      title: "Error Publishing Post",
      description: "An error occurred while publishing the post. Please try again.",
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  }
};

const SearchResultItem = ({ result, onOpen, setSelectedResult }) => {
  const toast = useToast(); // Call useToast inside the component

  return (
    <HStack pt={4} pb={4} borderWidth="1px" borderRadius="md">
      <Box flex="2">
        {result.og_image_url ? (
          <img src={result.og_image_url} alt={stripHtmlAndDecode(result.title)} />
        ) : (
          <Box color="white" textAlign="center">No Image</Box>
        )}
      </Box>
      <Box flex="6">
        <Box color="teal.300" fontWeight="bold" fontSize="xl">
          {stripHtmlAndDecode(result.title)}
        </Box>
        <Box fontSize="sm" color="gray.500">Last Modified: {formatDate(result.last_modified)}</Box>
        <Box color="white" mt={2}>{stripHtmlAndDecode(result.excerpt)}</Box>
      </Box>
      <Box flex="2" borderLeft="1px solid gray" display="grid" justifyContent="center" justifySelf="center" alignItems="center">
        <VStack>
          <Button
            colorScheme="teal"
            size="xs"
            onClick={() => {
              setSelectedResult(result);
              onOpen();
            }}
          >
            View
          </Button>
          <Link to={`/admin/search_canon/edit/${result.id}`}>
            <Button colorScheme="blue" size="xs">
              Edit
            </Button>
          </Link>
          { !result.og_image_url && (
            <Link to={`/admin/search_canon/details/${result.id}`}>
              <Button colorScheme="orange" size="xs">Generate Image</Button>
            </Link>
          )}
          { result.status === "draft" && (
            <Button
              colorScheme="green"
              size="xs"
              onClick={() => publishPost(result.id, toast)}
            >
              Publish
            </Button>
          )}
        </VStack>
      </Box>
    </HStack>
  );
};

export default SearchResultItem;
