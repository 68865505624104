import api from '../api/api';

const listRandomCanonDrafts = async () => {
  try {
    const response = await api.get(`/fetch_random_draft_posts?number_of_posts=7`);
    console.log('Random canon drafts API response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching random canon drafts:', error);
    throw error;
  }
};

export default listRandomCanonDrafts;