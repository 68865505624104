import api from '../api/api';

const listRandomCanonPublished = async () => {
  try {
    const response = await api.get(`/fetch_random_published_posts?number_of_posts=7`);
    return response.data;
  } catch (error) {
    console.error('Error fetching random canon published posts:', error);
    throw error;
  }
};

export default listRandomCanonPublished;