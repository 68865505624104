import api from '../api/api';

const createCanon = async (title, content) => {
  try {
    const response = await api.post(`/canon_create`, { title, content });
    return response.data;
  } catch (error) {
    console.error('Error creating canon:', error);
    throw error;
  }
};

export default createCanon;