// SearchCanon.js - This component displays a search input and search results based on the user's query.

import React, { useState } from 'react';
import { Input, Button, Box, VStack, HStack, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, useDisclosure, Spinner } from '@chakra-ui/react';
import searchCanon from '../utils/searchCanon';
import { stripHtmlAndDecode } from '../utils/textUtils'; // Import the new utility function
import SearchResultItem from './SearchResultItem';
import SearchRevisionResultItem from './SearchRevisionResultItem'; // Import the new component

const SearchCanon = ({ contextUsage, updateContext }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedResult, setSelectedResult] = useState(null);

  const handleSearch = async () => {
    setIsLoading(true);
    try {
      const data = await searchCanon(query);
      setResults(data);
    } catch (error) {
      console.error('Search failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

// Determine The Items Display Based on ContextUsage
const renderItemDisplay = () => {
  if (contextUsage === 'add_to_revision') {
    return (
      <Box>
        {results.map((result, index) => (
          <SearchRevisionResultItem
            key={index}
            result={result}
            onOpen={onOpen}
            setSelectedResult={setSelectedResult}
            updateContext={updateContext}
          />
        ))}
      </Box>
    );
  }

  // Default display
  return (
    <VStack spacing={4}>
      <Box>
        {results.map((result, index) => (
          <SearchResultItem
            key={index}
            result={result}
            onOpen={onOpen}
            setSelectedResult={setSelectedResult}
          />
        ))}
      </Box>
    </VStack>
  );
};

// Determine layout based on contextUsage
const renderLayout = () => {
  if (contextUsage === 'compact') {
    return (
      <HStack spacing={4}>
        <Input
          placeholder="Enter search term"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          color="white"
        />
        <Button onClick={handleSearch} colorScheme="teal" isDisabled={isLoading}>
          {isLoading ? <Spinner size="sm" /> : 'Search'}
        </Button>
      </HStack>
    );
  }

  // Default layout
  return (
    <VStack spacing={4}>
      <Input
        placeholder="Enter search term"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        color="white"
      />
      <Button onClick={handleSearch} colorScheme="teal" isDisabled={isLoading}>
        {isLoading ? <Spinner size="sm" /> : 'Search'}
      </Button>
    </VStack>
  );
};

return (
  <Box backgroundColor="black" p={4}>
    {renderLayout()}
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{selectedResult ? stripHtmlAndDecode(selectedResult.title) : ''}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box 
            color="black" 
            dangerouslySetInnerHTML={{ __html: selectedResult ? selectedResult.content.replace(/<a /g, '<a target="_blank" ') : '' }}
            sx={{
              a: {
                color: 'teal.500',
                textDecoration: 'underline',
                fontWeight: 'bold',
                _hover: {
                  color: 'teal.700',
                },
              },
            }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
    {renderItemDisplay()}
    </Box>

  );
};

export default SearchCanon;