import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import { Box, Button } from '@chakra-ui/react'; // Import Button from Chakra-UI
import './css/customQuillStyles.css'; // Import custom styles

// Add custom styles for the editor
const editorStyle = {
  minHeight: '150px', // Minimum height
  maxHeight: '500px', // Maximum height
  overflow: 'auto',   // Allow scrolling if content exceeds maxHeight
};

// Define custom toolbar options
const modules = {
  toolbar: [
    [{ 'header': '1'}, {'header': '2'}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image'],
    ['clean']
  ],
};

const WysiwygEditor = ({ initialContent, onSave, onContentChange }) => {
  const [content, setContent] = useState(initialContent);

  const handleSave = () => {
    onSave(content);
  };

  const handleChange = (value) => {
    setContent(value);
    onContentChange(value);
  };

  return (
    <Box>
      <ReactQuill
        value={content}
        onChange={handleChange}
        style={{ ...editorStyle, color: 'white' }}
        modules={modules} // Add modules prop here
      >
        
      </ReactQuill>
      <Box display="flex" justifyContent="center" mt={4}>
        <Button onClick={handleSave} colorScheme="teal">Save</Button>
      </Box>
    </Box>
  );
};

export default WysiwygEditor;
