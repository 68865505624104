import React, { useState, useEffect } from 'react';
import {
  Box,
  VStack,
  useDisclosure,
  Spinner,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';
import listCanonDrafts from '../utils/listCanonDrafts';
import SearchResultItem from './SearchResultItem';
import { stripHtmlAndDecode } from '../utils/textUtils';

const CanonDraftArticles = () => {
  useEffect(() => {
    handleList();
  }, []);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedResult, setSelectedResult] = useState(null);

  const handleList = async () => {
    try {
      const data = await listCanonDrafts();
      setResults(data);
    } catch (error) {
      console.error('Load of draft articles failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <VStack alignItems="center">
        <Spinner size="lg" color="teal.500" />
        <Text>Loading...</Text>
      </VStack>
    );
  }

  return (
    <Box backgroundColor="black" p={4}>
      <VStack spacing={4}>
        <Box>
          {results.map((result, index) => (
            <SearchResultItem
              key={index}
              result={result}
              onOpen={onOpen}
              setSelectedResult={setSelectedResult}
            />
          ))}
        </Box>
      </VStack>

      {/* Modal for viewing selected result */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {selectedResult ? stripHtmlAndDecode(selectedResult.title) : ''}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedResult && selectedResult.content ? (
              <Box
                color="black"
                dangerouslySetInnerHTML={{
                  __html: selectedResult.content.replace(/<a /g, '<a target="_blank" '),
                }}
                sx={{
                  a: {
                    color: 'teal.500',
                    textDecoration: 'underline',
                    fontWeight: 'bold',
                    _hover: {
                      color: 'teal.700',
                    },
                  },
                }}
              />
            ) : (
              <Text>No content available for this draft.</Text>
            )}
          </ModalBody>

        </ModalContent>
      </Modal>
    </Box>
  );
};

export default CanonDraftArticles;
