import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import WysiwygEditor from './WysiwygEditor';
import api from '../api/api';
import { Heading, Box, Button, Spinner } from '@chakra-ui/react'; // Import the Heading component

const EditCanonPost = () => {
  const { id } = useParams();
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await api.get(`/canon_post/${id}`);
        setContent(response.data.content);
        setTitle(response.data.title); // Set the title
      } catch (error) {
        console.error('Error fetching post:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [id]);

  const handleSave = async () => {
    try {
      await api.post(`/canon_update/${id}`, { content });
      alert('Post updated successfully!');
    } catch (error) {
      console.error('Error updating canon post:', error);
    }
  };

  if (loading) return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <Spinner size="xl" color="teal.500" />
      <div>Loading...</div>
    </Box>
  );

  return (
    <Box>
      <Heading as="h1" size="2xl" textAlign="center" color="white">Edit Post</Heading>
      <Heading as="h2" size="lg" textAlign="center" color="gray.300" mt={2}>{title}</Heading> {/* Display the post title */}
      <WysiwygEditor initialContent={content} onSave={handleSave} onContentChange={setContent} style={{ color: 'white' }} />
      <Box display="flex" justifyContent="center" mt={5}>
      <Button colorScheme="teal" onClick={() => navigate(`/admin/generate_canon_revision/${id}`)}>
          Generate A Revision
        </Button>
      </Box>
    </Box>
  );
};

export default EditCanonPost;