import React, { useState, useEffect } from 'react';
import {Box, VStack, useDisclosure, Spinner, Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody} from '@chakra-ui/react';
import listRandomCanonPublished from '../utils/listRandomCanonPublished';
import ListResultItem from './ListResultItem';
import { stripHtmlAndDecode } from '../utils/textUtils';

const CanonRandomPublishedArticles = () => {
  useEffect(() => {
    handleList();
  }, []);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedResult, setSelectedResult] = useState(null);

  const handleList = async () => {
    try {
      const data = await listRandomCanonPublished();
      setResults(data);
    } catch (error) {
      console.error('Load of published articles failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <VStack alignItems="center">
        <Spinner size="xl" color="teal.500"/>
        <Text>Loading...</Text>
      </VStack>
    );
  }

  return (
    <Box backgroundColor="black" p={4}>
      <VStack spacing={4}>
        <Box>
          {results.map((result, index) => (
            <ListResultItem
              key={index}
              result={result}
              onOpen={onOpen}
              setSelectedResult={setSelectedResult}
            />
          ))}
        </Box>
      </VStack>

            {/* Modal for viewing selected result */}
            <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {selectedResult ? stripHtmlAndDecode(selectedResult.title) : ''}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedResult && selectedResult.content ? (
              <Box
                color="black"
                dangerouslySetInnerHTML={{
                  __html: selectedResult.content.replace(/<a /g, '<a target="_blank" '),
                }}
                sx={{
                  a: {
                    color: 'teal.500',
                    textDecoration: 'underline',
                    fontWeight: 'bold',
                    _hover: {
                      color: 'teal.700',
                    },
                  },
                }}
              />
            ) : (
              <Text>No content available for this draft.</Text>
            )}
          </ModalBody>

        </ModalContent>
      </Modal>
    </Box>
  );
};

export default CanonRandomPublishedArticles;