import React, { useState } from 'react';
import { Box, Button, FormControl, FormLabel, Input, VStack, useToast, Textarea } from '@chakra-ui/react';
import createCanon from '../utils/createCanon'; // Import the createCanon utility

const AddCanonEntry = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const toast = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createCanon(title, content); // Use createCanon to send data to the API
      toast({
        title: "Canon Entry added.",
        description: "Your new canon entry has been successfully added.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setTitle(''); // Clear the form fields
      setContent('');
    } catch (error) {
      toast({
        title: "Error",
        description: "There was an error adding the canon entry.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box p={4} bg="gray.700" color="white" borderRadius="md">
      <form onSubmit={handleSubmit}>
        <VStack spacing={4}>
          <FormControl id="title" isRequired>
            <FormLabel>Title</FormLabel>
            <Input
              placeholder="Enter title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormControl>
          <FormControl id="content" isRequired>
            <FormLabel>Content</FormLabel>
            <Textarea
              placeholder="Enter content"
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
          </FormControl>
          <Button type="submit" colorScheme="teal" width="full">
            Add Canon Entry
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default AddCanonEntry;