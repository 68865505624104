import api from '../api/api';

// Create an array of key/value pairs to store the count of posts in the database and the count of posts in the WordPress database
// Initialize the array with the count of posts in the database and the count of posts in the WordPress database
let arrayOfInfo = {
    "CountHere": 0,
    "CountThere": 0,
  };

export const getArrayOfInfo = () => ({ ...arrayOfInfo });

// Create a function to fetch the count of posts in the WordPress database

const fetchPostsThereCount = async () => {
    try {
      const response = await api.get(`/count_posts`);
      // Update the count of posts in the WordPress database in the array
        arrayOfInfo["CountThere"] = response.data.count;
    } catch (error) {
      console.error('Error fetching user information:', error);
      throw error;
    }
  };

const fetchPostHereCount = async () => {
  try {
    const response = await api.get(`/count_posts_in_db`);
    // Update the count of posts in the database in the array
    arrayOfInfo["CountHere"] = response.data.count;
  } catch (error) {
    console.error('Error fetching user information:', error);
    throw error;
  }
};

export const initializePostCounts = async () => {
    await Promise.all([fetchPostsThereCount(), fetchPostHereCount()]);
  };

export default {
  getArrayOfInfo,
  initializePostCounts
};